<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row class="hideOnPagePrint">
        <v-col class="d-flex flex-row align-center">
          <v-btn @click="$router.go(-1)" text><v-icon>mdi-arrow-left</v-icon></v-btn>
          <h1>Register Report</h1>
          <v-btn v-if="data && data.length>0" @click="printData" class="ml-2" fab color="info" x-small><v-icon>mdi-printer</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-row class="hideOnPagePrint">
        <v-col>
          <h3>Filters</h3>
          <span class="d-flex flex-row">
<!--            <v-select class="mr-2" outlined hide-details label="Register Date" :items="registerStatuses" v-model="filters.status"/>-->
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="filters.startDate"
                    label="Start Date"
                    readonly
                    outlined
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="filters.startDate"
              />
            </v-menu>
            <v-btn class="mt-2 mx-2" color="warning" @click="filters.endDate=filters.startDate"><v-icon>mdi-content-copy</v-icon><v-icon>mdi-arrow-right</v-icon></v-btn>
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="filters.endDate"
                    label="End Date"
                    readonly
                    outlined
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="filters.endDate"
              />
            </v-menu>
            <v-btn :loading="loader" class="mt-2 ml-2" fab small :disabled="!filters.startDate || !filters.endDate" color="info" @click="fetchReport"><v-icon>mdi-check</v-icon></v-btn>
            <v-spacer/>
            <v-spacer/>
          </span>

          <span class="d-flex flex-row py-2">
            <v-btn class="mr-2" color="info" @click="dailySales">Today</v-btn>
            <v-btn class="mr-2" color="info" @click="monthlySales">This Month</v-btn>
            <v-btn class="mr-2" color="info" @click="yearlySales">This Year</v-btn>
          </span>

          <span class="d-flex flex-row">
            <v-autocomplete
                style="max-width: 800px;"
                class="mr-1"
                v-model="filters.branch"
                :items="allBranches"
                outlined
                hide-details
                @change="branchUpdated"
                label="Branches"
                clearable
            />
            <v-autocomplete
                style="max-width: 800px;"
                class="mr-1"
                :disabled="!filters.branch||filters.branch.length===0||registerLoading"
                v-model="filters.register"
                :items="registers"
                item-text="name" item-value="id"
                outlined
                hide-details
                :loading="registerLoading"
                label="Registers"
                clearable
            />
            <v-select class="" outlined hide-details label="Register State" :items="registerStates" v-model="filters.state"/>
          </span>
          <span class="d-flex flex-row">
            <v-switch class="mr-5" v-model="filters.showBreakdowns" :label="`${filters.showBreakdowns?'Show Breakdowns':'Hide Breakdown'}`"/>
            <v-switch class="mr-5" v-model="filters.breakdownType" :label="`${filters.breakdownType?'Types':'Users'}`"/>
          </span>
        </v-col>
      </v-row>
<!--      <v-row class="hideOnPagePrint mt-n10">-->
<!--        <v-col class="d-flex flex-row">-->
<!--          <v-switch class="mr-5" label="Show User Breakdown" v-model="uiControl.userBreakdown"/>-->
<!--          <v-switch class="mr-5" label="Show Daily Breakdown" v-model="uiControl.dailyBreakdown"/>-->
<!--        </v-col>-->
<!--      </v-row>-->
      <v-row v-if="data && data.length===0">
        <v-col>
          <span class="red--text">No records found with the provided filters.</span>
        </v-col>
      </v-row>
      <v-row v-if="data">
        <v-col>
          <span>Showing sessions for {{utils.parseDate(loadedFilters.startDate, 2)}}{{loadedFilters.startDate!==loadedFilters.endDate?` - ${utils.parseDate(loadedFilters.endDate, 2)}`:''}}</span>
          <span v-if="loadedFilters.branch">
            <br>
            <span>in Branch: {{lookupBranch(loadedFilters.branch)}}</span>
          </span>
          <span v-if="loadedFilters.register">
            <br>
            <span>for Register: {{registers.find(x => x.id===loadedFilters.register)?.name ||`ID: ${loadedFilters.register}`}}</span>
          </span>
          <span>
            <br>
            <span>that are: {{registerStates.find(x => x.value===loadedFilters.state)?.text ||`ID: ${loadedFilters.state}`}} {{loadedFilters.state===-1?' Open and Closed':''}}</span>
          </span>
          <span v-if="filters.showBreakdowns">
            <br>
            <span>Breaking Down: {{loadedFilters.breakdownType?'Payment Types':'Users'}}</span>
          </span>
        </v-col>
      </v-row>
      <v-row v-if="data && data.length>0">
        <v-col class="d-flex flex-column align-start">
          <table style="width: 100%;">
            <thead>
            <tr>
              <th class="text-left"></th>
              <th class="d-flex flex-row justify-space-between px-2">
                <span>Summary</span>
                <span style="font-weight: normal; font-size: 14px;">Expected/Counted</span>
              </th>
              <th class="text-left">Opened By</th>
              <th class="text-left">Closed By</th>
              <th class="text-left">Opened At</th>
              <th class="text-left">Closed At</th>
              <th class="hideOnPagePrint text-left">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(sess, index) in data" :key="index">
              <td :style="`vertical-align: top; font-size: 14px;`">{{index+1}}</td>
<!--              <td :style="`vertical-align: top; font-size: 14px;`">{{sess.id}} {{sess.uuid}}</td>-->
              <td :style="`vertical-align: top; font-size: 14px;`" class="d-flex flex-column px-2">
                <span class="d-flex flex-row justify-space-between blue--text">
                  <span>Opening Float:</span>
                  <span>{{utils.formatCurrency(sess.openingFloat)}}</span>
                </span>
                <br v-if="sess.RegisterSessionPaymentTypes>0">
                <span v-for="(pt, j) in sess.RegisterSessionPaymentTypes" :key="j+'pt'" class="d-flex flex-column">
                  <span class="d-flex flex-row justify-space-between">
                    <span>{{lookupPaymentType(pt.paymentTypeId)}}: </span>
                    <span :class="`${utils.formatCurrency(pt.metadata.expected)!==utils.formatCurrency(pt.amount)?'red--text':'green--text'}`">{{utils.formatCurrency(pt.metadata.expected)}}/{{utils.formatCurrency(pt.amount)}}</span>
                  </span>
                  <span class="pl-5 d-flex flex-row justify-space-between" v-if="pt.metadata.cashMovements!=null">
                    <span>Cash Movements</span>
                    <span>{{utils.formatCurrency(pt.metadata.cashMovements)}}</span>
                  </span>
                  <span class="pl-5 d-flex flex-row justify-space-between" v-if="pt.metadata.cashReceived!=null">
                    <span>Cash Payments From Orders</span>
                    <span>{{utils.formatCurrency(pt.metadata.cashReceived)}}</span>
                  </span>
                  <span class="pl-5 d-flex flex-row justify-space-between" v-if="pt.metadata.cashRedeemed!=null">
                    <span>Cash Redeemed</span>
                    <span>{{utils.formatCurrency(pt.metadata.cashRedeemed)}}</span>
                  </span>
                </span>
                <span v-if="!sess.closedAt || (filters.showBreakdowns && sess.closedAt)">
                  <hr>
                  <b>{{loadedFilters.breakdownType?'Types':'Users'}}</b>
                  <span v-if="loadedFilters.breakdownType">
                    <span v-for="(paymentType, j) in sess.paymentData" :key="j+'pt'" class="d-flex flex-column">
                      <span class="d-flex flex-column">
                        <span class="d-flex flex-row justify-space-between">
                          <span>{{lookupPaymentType(paymentType.paymentType)}}</span>
                          <b>{{utils.formatCurrency(paymentType.total)}}</b>
                        </span>
                        <span v-if="filters.showBreakdowns">
                          <span class="pl-5 d-flex flex-row justify-space-between" v-for="(user, k) of paymentType.users" :key="k">
                            <span>{{lookupUsername(user.userId)}}: </span>
                            <span>{{utils.formatCurrency(user.total)}}</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                  <span v-else>
                    <span v-for="(user, j) in sess.userData" :key="j+'u'" class="d-flex flex-column">
                      <span class="d-flex flex-column">
                        <span class="d-flex flex-row justify-space-between">
                          <span>{{lookupUsername(user.userId)}}</span>
                          <b>{{utils.formatCurrency(user.total)}}</b>
                        </span>
                        <span v-if="filters.showBreakdowns">
                          <span class="pl-5 d-flex flex-row justify-space-between" v-for="(pt, k) of user.types" :key="k">
                            <span>{{lookupPaymentType(pt.paymentTypeId)}}: </span>
                            <span>{{utils.formatCurrency(pt.total)}}</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </td>
              <td :style="`vertical-align: top; font-size: 14px;`">{{lookupUsername(sess.openedBy)}}</td>
              <td :style="`vertical-align: top; font-size: 14px;`">{{lookupUsername(sess.closedBy)}}</td>
              <td :style="`vertical-align: top; font-size: 14px;`">{{utils.formatDate(sess.openedAt, 'withTime')}}</td>
              <td :style="`vertical-align: top; font-size: 14px;`">{{utils.formatDate(sess.closedAt, 'withTime')}}</td>
              <td class="hideOnPagePrint">
                <v-btn @click="$router.push(`/registers/view/${sess.uuid}`)" x-small color="info" fab><v-icon>mdi-arrow-right</v-icon></v-btn>
              </td>
            </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>

    </v-container>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
  table{
    border-collapse: collapse;
  }
  tr{
    border-bottom: 1px solid #ccc;
  }
  @media print {
    .record {
      break-inside: avoid;
    }
  }
</style>
<script>
  import axios from 'axios';
  import moment from 'moment'
  import {mapGetters} from "vuex";
  import utils from "../../plugins/helpers"
  export default {
    data () {
      return {
        utils: utils,
        deleteDialog: false,
        deleteConfirmed: true,
        loader: false,
        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        filters: {
          startDate: null,
          endDate: null,
          register: null,
          branch: null,
          breakdownType: true,
          // status: 1,
          state: -1,
          showBreakdowns: true
        },
        loadedFilters: {
          startDate: null,
          endDate: null,
          register: null,
          branch: null,
          breakdownType: true,
          // status: 1,
          state: -1,
          showBreakdowns: true
        },

        allBranches: [],
        registers: [],
        registerLoading: false,

        registerStatuses: [
          {text: "Closed Between", value: 1},
          {text: "Opened Between", value: 0},
          {text: "Both Between", value: -1},
        ],

        registerStates: [
          {text: "Closed", value: 1},
          {text: "Open", value: 0},
          {text: "Both", value: -1},
        ],

        totals: null,
        data: null,
      }
    },
    async mounted(){
      if(this.isAllowed('order', 'excludeBranchFilter')){
        this.allBranches = this.getBranches.map(x => {
          return {
            text: x.name,
            value: x.id
          }
        });
      }
      else {
        this.allBranches = this.getUser?.metadata?.branchData?.allowed.map(x => {
          return {
            text: this.lookupBranch(x),
            value: x
          };
        });
      }

      await this.getAllData();
    },
    computed: {
      ...mapGetters(['getEndpoint', "isAllowed", 'lookupUsername', 'getUserCache', 'getGlobalValue', 'paymentTypes', 'getBranches', 'lookupBranch', 'getBranch', 'lookupPaymentType']),
    },
    methods: {
      rowClick(row){
        this.$router.push({ path: `/orders/view/${row.id}`})
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      computeBreakdownTotal(pt){
        let total = 0;

        let found = pt
        if(found){
          total+=parseInt(found.metadata.amountBreakdown.x100||0)*100;
          total+=parseInt(found.metadata.amountBreakdown.x50||0)*50;
          total+=parseInt(found.metadata.amountBreakdown.x20||0)*20;
          total+=parseInt(found.metadata.amountBreakdown.x10||0)*10;
          total+=parseInt(found.metadata.amountBreakdown.x5||0)*5;
          total+=parseInt(found.metadata.amountBreakdown.x1||0)*1;
          total+=parseInt(found.metadata.amountBreakdown.x025||0)*0.25;
          total+=parseInt(found.metadata.amountBreakdown.x010||0)*0.10;
          total+=parseInt(found.metadata.amountBreakdown.x05||0)*0.05;
          total+=parseInt(found.metadata.amountBreakdown.x01||0)*0.01;
        }

        return utils.pff2(total);
      },
      async branchUpdated(){
        try{
          this.registerLoading = true;

          if(!this.filters.branch){
            this.registers = []
            this.filters.register = null;
            return
          }

          let res = await axios.get(`${this.getEndpoint}/api/registers/byBranch/${this.filters.branch}`)
          if(res.data.error) throw res.data.error
          this.registers = res.data.data

          if(this.registers.length>0){
            this.filters.register = this.registers[0].id
          }
        }
        catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.registerLoading = false;
        }
      },
      async getAllData(){
        try {
          this.loader = true;

          let now = new Date();
          let firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
          let lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
          let branch = null;
          let register = null;
          let breakdownType = true;
          let showBreakdowns = true;
          // let status = 1;
          let state = -1;

          let found = this.allBranches.find(x => x.value===this.getBranch);
          if(found){
            branch = found.value;
          }

          if(this.$route.query.startDate){
            firstDay = new Date(this.$route.query.startDate);
          }
          if(this.$route.query.endDate){
            lastDay = new Date(this.$route.query.endDate);
          }

          this.filters.startDate = this.utils.parseDate(firstDay);
          this.filters.endDate = this.utils.parseDate(lastDay);

          if(this.$route.query.branch && !isNaN(this.$route.query.branch)){
            branch = parseInt(this.$route.query.branch)
          }
          if(this.$route.query.register && !isNaN(this.$route.query.register)){
            register = parseInt(this.$route.query.register)
          }
          // if(this.$route.query.status && !isNaN(this.$route.query.status)){
          //   status = parseInt(this.$route.query.status)
          // }
          if(this.$route.query.state && !isNaN(this.$route.query.state)){
            state = parseInt(this.$route.query.state)
          }
          if(this.$route.query.breakdownType){
            breakdownType = this.$route.query.breakdownType==="true"
          }
          if(this.$route.query.showBreakdowns){
            showBreakdowns = this.$route.query.showBreakdowns==="true"
          }

          this.filters.branch = branch
          this.filters.register = register
          this.filters.state = state
          this.filters.breakdownType = breakdownType
          this.filters.showBreakdowns = showBreakdowns

          if(this.filters.branch){
            await this.branchUpdated();
          }

          this.$forceUpdate();
        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async fetchReport(){
        try {
          this.loader = true;
          this.data = null;
          this.totals = null;

          if(!this.filters.register) throw "You must select a register."

          let res = await axios.post(`${this.getEndpoint}/api/reporting/registerreport`, this.filters)
          if(res.data.error) throw res.data.error

          if(!this.$route.query.startDate){
            await this.$router.push({query: {...this.filters}});
          }
          else{
            let changed = false;

            if(Object.keys(this.$route.query).length!==Object.keys(this.filters).length) changed = true
            if(!changed){
              for(let key of Object.keys(this.$route.query)){
                if(this.filters[key]!=this.$route.query[key] && !Array.isArray(this.filters[key])){
                  if((this.$route.query[key]==="true")!==this.filters[key]){
                    changed = true;
                    break;
                  }
                }
                if(Array.isArray(this.filters[key])){
                  if(this.filters[key].length!==this.$route.query[key]?.length){
                    changed = true;
                    break;
                  }
                  for(let i=0;i<this.filters[key].length;i++){
                    if(this.filters[key][i]!=this.$route.query[key][i]){
                      changed = true;
                      break;
                    }
                  }
                }
              }
            }
            if(changed){
              await this.$router.replace({query: {...this.filters}});
            }
          }

          if(res.data.data.length===0) throw "No records found within the provided filters."

          this.loadedFilters = {...this.filters}

          this.data = res.data.data;
        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async dailySales(){
        this.filters.startDate = moment().format("YYYY-MM-DD");
        this.filters.endDate = moment().format("YYYY-MM-DD");
      },
      async monthlySales(){
        this.filters.startDate = moment().startOf('month').format("YYYY-MM-DD");
        this.filters.endDate = moment().endOf('month').format("YYYY-MM-DD");
      },
      async yearlySales(){
        this.filters.startDate = moment().startOf('year').format("YYYY-MM-DD");
        this.filters.endDate = moment().endOf('year').format("YYYY-MM-DD");
      },
      async printData(){
        let els = document.querySelectorAll(".hideOnPagePrint");
        let defaults = [];
        for(let el of els){
          defaults.push(el.style.display);
          el.style.setProperty("display", "none", "important");
        }

        let els2 = document.querySelectorAll(".adjustForPagePrint");
        let defaults2 = [];
        // let defaults3 = [];
        for(let el of els2){
          defaults2.push(el.style.padding);
          // defaults3.push(el.style.paddingRight);
          el.style.setProperty("padding", "0px 50px 0px 0px");
          // el.style.setProperty("paddingRight", "20px");
        }

        window.print();

        for(let i=0;i<els.length;i++){
          els[i].style.setProperty("display", defaults[i], "");
        }

        for(let i=0;i<els2.length;i++){
          els2[i].style.setProperty("padding", defaults2[i], "");
        }
      },
    }
  }
</script>
