<template>
  <div class="" style="width: 100%; margin-bottom: 100px;">
    <v-container fluid>
      <v-row>
        <v-col class='d-flex flex-row align-center'>
          <v-btn fab x-small @click="$router.go(-1)">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <h1 style="line-height: 26px; font-size: 26px; text-align: left; margin-left: 10px;">{{serialNaming.singular}} Viewer</h1>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
              style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field style="width: 500px;" label="Currently Viewing" @change="lookupSerial" dense outlined v-model="currentlyViewing"></v-text-field>
          <v-btn color="success" @click="lookupSerial">Search</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="data">
          <h3 v-if="!loader && data && data.length == 0">No records could be found for this serial.</h3>
          <div v-else>
            <h3>Showing {{ data.length }} results for {{ serialNaming.singular }} #{{ $route.params.id }}</h3>
            <v-card class="mt-2" outlined v-for="item in data" :key="item.id">
              <v-card-text class="d-flex flex-column">
                <div>Log ID #{{item.id}}</div>
                <b>Status: {{ translateLogItemDataToNote(item) }}</b>
                <div>Customer: 
                  <router-link v-if="item.data.customerId" :to="'/customers/view/'+item.data.customerId">{{ item.data.customerId }}</router-link>
                  <span v-else>-</span>
                </div>
                <div>Location: {{ lookupLocation(item.data.locationId) || '-'}}</div>
                <div>User: {{ lookupUsername(item.userId) }}</div>
                <div>{{ helpers.formatDate(item.createdAt, 'withTime') }}</div>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
        <v-col v-else>
          <h3>No records could be found for this serial.</h3>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<style scoped>
.spacedCard{
  padding: 20px;
  margin-bottom: 20px;
}
</style>

<script>
import axios from 'axios';
import { mapGetters, mapMutations } from 'vuex'
import helpers from '../../plugins/helpers';
export default {
  components: {
  },
  data () {
    return {
      helpers: helpers,
      printers: [],
      loader: false,
      data: [],
      snackObj: {
        color: "",
        state: false,
        text: "",
      },
      currentlyViewing: null,
    }
  },
  async mounted(){
    window.scrollTo({top: 0});
    this.currentlyViewing = this.$route.params.id
    await this.getAllData();
  },

  computed: {
    ...mapGetters(['getEndpoint', "isAllowed", 'getUser', 'getGlobalValue', 'scanBus', 'lookupUsername', 'lookupLocation', 'serialNaming']),
  },
  methods: {
    ...mapMutations([
      'lockGlobalQueryBc',
      'unlockGlobalQueryBc',
      'setScanBus',
      'resetScanBus',
    ]),
    async lookupSerial(){
      this.$router.push({path: `/products/serial/${this.currentlyViewing}`})
    },
    translateLogItemDataToNote(item){
      console.log(item)
      if(item.route.includes('/api/orders/changeStatus/voided/')){
        return `The ${this.serialNaming.singular} was returned to its original location when the order was voided.`
      }
      if(item.route.includes('/api/orders/changeStatus/sealed/')&& item.data?.metadata?.sentToDispatch){
        return `The ${this.serialNaming.singular} is now being handled by the dispatcher module when order #${item.data?.OrderSerialJoin?.orderId} was sealed.`
      }
      let re = /api\/products\/\d\/serials/g
      if(item.route.match(re)?.length > 0 && item.action=="create"){
        return `The ${this.serialNaming.singular} was created using the product manager interface.`
      }
      if(item.route.includes('/api/receivingreports/seal/')){
        return `The ${this.serialNaming.singular} was created using the receiving report interface.`
      }
      if(item.route.includes('/api/receivingreports/seal/')){
        return `The ${this.serialNaming.singular} was created using the receiving report interface.`
      }
      if(item.route.includes('/api/dispatch/confirm/')){
        return `The ${this.serialNaming.singular} was delivered to the customer. [Dispatch #${item.data?.OrderSerialJoin?.orderId}].`
      }
      if(item.route.includes('/api/inventorytransfers/receiveIn/')){
        return `The ${this.serialNaming.singular} was received into the ${this.lookupLocation(item.data.locationId)} using the inventory transfer interface.`
      }
      if(item.route.includes('/api/inventorytransfers/sendOut/')){
        return `The ${this.serialNaming.singular} was sent out using the inventory transfer interface.`
      }
      return "-"
    },
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    async getAllData(){
      try {
        this.loader = true
        let res = await axios.post(`${this.getEndpoint}/api/logs/serialHistory/${this.$route.params.id}`)
        if(res.data.error) throw res.data.error
        this.data = res.data.data
        console.log(this.data)
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error")
      } finally {
        this.loader = false
      }
    }, 
  }
}
</script>