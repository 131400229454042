<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row class="hideOnPagePrint">
        <v-col class="d-flex flex-row align-center">
          <v-btn @click="$router.go(-1)" text><v-icon>mdi-arrow-left</v-icon></v-btn>
          <h1>Vat Sales</h1>
          <v-btn v-if="data && data.length>0" @click="printData" class="ml-2" fab color="info" x-small><v-icon>mdi-printer</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-row class="hideOnPagePrint">
        <v-col>
          <h3>Filters</h3>
          <span class="d-flex flex-row">
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="filters.startDate"
                    label="Start Date"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="filters.startDate"
              />
            </v-menu>
            <v-btn class="mt-2 mx-2" color="warning" @click="filters.endDate=filters.startDate"><v-icon>mdi-content-copy</v-icon><v-icon>mdi-arrow-right</v-icon></v-btn>
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="filters.endDate"
                    label="End Date"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="filters.endDate"
              />
            </v-menu>
            <v-btn :loading="loader" class="mt-2 ml-2" fab small :disabled="!filters.startDate || !filters.endDate" color="info" @click="fetchReport"><v-icon>mdi-check</v-icon></v-btn>
            <v-spacer/>
            <v-spacer/>
          </span>

          <span class="d-flex flex-row">
            <v-btn class="mr-2" color="info" @click="dailySales">Today</v-btn>
            <v-btn class="mr-2" color="info" @click="monthlySales">This Month</v-btn>
            <v-btn class="mr-2" color="info" @click="yearlySales">This Year</v-btn>
          </span>

          <span class="d-flex flex-row mt-3">
            <v-autocomplete
                class="mr-1"
                :disabled="filters.excludeUser.length>0"
                v-model="filters.sealedByUser"
                :items="getUserCache"
                :item-text="item=>(item.firstName||'')+' '+(item.lastName||'')+(item.deletedAt?' (DELETED USER)':'')"
                item-value="id"
                outlined
                multiple
                label="Orders Sealed By User"
                clearable
            ></v-autocomplete>
            <v-btn :disabled="!filters.excludeUser && !filters.sealedByUser" class="mt-2" color="warning" @click="swapUserFields"><v-icon>mdi-swap-horizontal</v-icon></v-btn>
            <v-autocomplete
                class="ml-1"
                :disabled="filters.sealedByUser.length>0"
                v-model="filters.excludeUser"
                :items="getUserCache"
                multiple
                :item-text="item=>(item.firstName||'')+' '+(item.lastName||'')+(item.deletedAt?' (DELETED USER)':'')"
                item-value="id"
                outlined
                label="Exclude Orders By User"
                clearable
            ></v-autocomplete>
          </span>

          <span>
            <v-autocomplete
                style="max-width: 800px;"
                class="ml-1"
                v-model="filters.branches"
                :items="allBranches"
                multiple
                outlined
                label="Branches"
                clearable
            ></v-autocomplete>
          </span>
        </v-col>
      </v-row>
      <v-row class="hideOnPagePrint mt-n10">
        <v-col class="d-flex flex-row">
          <v-switch class="mr-5" label="Show User Breakdown" v-model="uiControl.userBreakdown"/>
          <v-switch class="mr-5" label="Show Daily Breakdown" v-model="uiControl.dailyBreakdown"/>
        </v-col>
      </v-row>
      <v-row v-if="data && data.length===0">
        <v-col>
          <span class="red--text">No records found with the provided filters.</span>
        </v-col>
      </v-row>
      <v-row v-if="data">
        <v-col>
          <span>Showing data for {{utils.parseDate(loadedFilters.startDate, 2)}}{{loadedFilters.startDate!==loadedFilters.endDate?` - ${utils.parseDate(loadedFilters.endDate, 2)}`:''}}</span>
          <span v-if="loadedFilters.branches.length>0">
            <br>
            <span>in Branches: {{loadedFilters.branches.map(x => lookupBranch(x)).join(", ")}}</span>
          </span>
          <span v-if="loadedFilters.sealedByUser.length>0">
            <br>
            <span>for Users: {{loadedFilters.sealedByUser.map(x => lookupUsername(x)).join(", ")}}</span>
          </span>
          <span v-if="loadedFilters.excludeUser.length>0">
            <br>
            <span>excluding Users: {{loadedFilters.excludeUser.map(x => lookupUsername(x)).join(", ")}}</span>
          </span>
        </v-col>
      </v-row>
      <v-row v-if="totals && data.length>1">
        <v-col class="d-flex flex-column align-start">
          <h3 style="font-size: 20px;">Totals For Selected Data</h3>
          <b style="font-size: 17px;">Non-VAT Sales: {{utils.formatCurrency(totals.nonVatSales)}}</b>
          <span v-if="uiControl.userBreakdown" class="d-flex flex-column align-start">
            <span v-for="(user, u) of totals.users" :key="u+'nonVatSales'" class="ml-3" style="font-size: 16px;">{{lookupUsername(user.userId)}}: {{utils.formatCurrency(user.totals.nonVatSales)}}</span>
          </span>
          <b style="font-size: 17px;">VAT Sales: {{utils.formatCurrency(totals.vatSales)}}</b>
          <span v-if="uiControl.userBreakdown" class="d-flex flex-column align-start">
            <span v-for="(user, u) of totals.users" :key="u+'vatSales'" class="ml-3" style="font-size: 16px;">{{lookupUsername(user.userId)}}: {{utils.formatCurrency(user.totals.vatSales)}}</span>
          </span>
          <b style="font-size: 17px;">VAT: {{utils.formatCurrency(totals.vat)}}</b>
          <span v-if="uiControl.userBreakdown" class="d-flex flex-column align-start">
            <span v-for="(user, u) of totals.users" :key="u+'vat'" class="ml-3" style="font-size: 16px;">{{lookupUsername(user.userId)}}: {{utils.formatCurrency(user.totals.vat)}}</span>
          </span>
          <b style="font-size: 17px;">Discounts: {{utils.formatCurrency(totals.discounts)}}</b>
          <span v-if="uiControl.userBreakdown" class="d-flex flex-column align-start">
            <span v-for="(user, u) of totals.users" :key="u+'discounts'" class="ml-3" style="font-size: 16px;">{{lookupUsername(user.userId)}}: {{utils.formatCurrency(user.totals.discounts)}}</span>
          </span>
        </v-col>
      </v-row>
      <v-row v-if="data && uiControl.dailyBreakdown">
        <v-col>
          <span v-for="(date, i) of data" :key="i" class="d-flex flex-column mb-2 record">
            <h3 style="font-size: 18px;">Totals for {{utils.parseDate(new Date(date.date), 2)}}</h3>
            <b style="font-size: 15px;">Non-VAT Sales: {{utils.formatCurrency(date.totals.nonVatSales)}}</b>
            <span v-if="uiControl.userBreakdown" class="d-flex flex-column align-start">
              <span v-for="(user, u) of date.users" :key="u+'nonVatSales'" class="ml-3" style="font-size: 14px;">{{lookupUsername(user.userId)}}: {{utils.formatCurrency(user.totals.nonVatSales)}}</span>
            </span>
            <b style="font-size: 15px;">VAT Sales: {{utils.formatCurrency(date.totals.vatSales)}}</b>
            <span v-if="uiControl.userBreakdown" class="d-flex flex-column align-start">
              <span v-for="(user, u) of date.users" :key="u+'vatSales'" class="ml-3" style="font-size: 14px;">{{lookupUsername(user.userId)}}: {{utils.formatCurrency(user.totals.vatSales)}}</span>
            </span>
            <b style="font-size: 15px;">VAT: {{utils.formatCurrency(date.totals.vat)}}</b>
            <span v-if="uiControl.userBreakdown" class="d-flex flex-column align-start">
              <span v-for="(user, u) of date.users" :key="u+'vat'" class="ml-3" style="font-size: 14px;">{{lookupUsername(user.userId)}}: {{utils.formatCurrency(user.totals.vat)}}</span>
            </span>
            <b style="font-size: 15px;">Discounts: {{utils.formatCurrency(date.totals.discounts)}}</b>
            <span v-if="uiControl.userBreakdown" class="d-flex flex-column align-start">
              <span v-for="(user, u) of date.users" :key="u+'discounts'" class="ml-3" style="font-size: 14px;">{{lookupUsername(user.userId)}}: {{utils.formatCurrency(user.totals.discounts)}}</span>
            </span>
          </span>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
  table{
    border-collapse: collapse;
  }
  tr{
    border-bottom: 1px solid #ccc;
  }
  @media print {
    .record {
      break-inside: avoid;
    }
  }
</style>
<script>
  import axios from 'axios';
  import moment from 'moment'
  import {mapGetters} from "vuex";
  import utils from "../../plugins/helpers"
  export default {
    data () {
      return {
        utils: utils,
        deleteDialog: false,
        deleteConfirmed: true,
        loader: false,
        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        filters: {
          startDate: null,
          endDate: null,
          sealedByUser: [],
          excludeUser: [],
          branches: []
        },
        loadedFilters: {
          startDate: null,
          endDate: null,
          sealedByUser: [],
          excludeUser: [],
          branches: []
        },
        uiControl: {
          userBreakdown: true,
          dailyBreakdown: true,
        },

        allBranches: [],

        totals: null,
        data: null,
      }
    },
    async mounted(){
      if(this.isAllowed('order', 'excludeBranchFilter')){
        this.allBranches = this.getBranches.map(x => {
          return {
            text: x.name,
            value: x.id
          }
        });
      }
      else{
        this.allBranches = this.getUser?.metadata?.branchData?.allowed.map(x => {
          return {
            text: this.lookupBranch(x),
            value: x
          };
        });
      }

      await this.getAllData();
    },
    computed: {
      ...mapGetters(['getEndpoint', "isAllowed", 'lookupUsername', 'getUserCache', 'getGlobalValue', 'paymentTypes', 'getBranches', 'lookupBranch', 'getUser']),
    },
    methods: {
      rowClick(row){
        this.$router.push({ path: `/orders/view/${row.id}`})
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async getAllData(){
        try {
          this.loader = true;

          let now = new Date();
          let firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
          let lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
          let sealedByUser = [];
          let excludeUser = [];
          let branches = [];

          if(this.$route.query.startDate){
            firstDay = new Date(this.$route.query.startDate);
          }
          if(this.$route.query.endDate){
            lastDay = new Date(this.$route.query.endDate);
          }

          this.filters.startDate = this.utils.parseDate(firstDay);
          this.filters.endDate = this.utils.parseDate(lastDay);

          if(this.$route.query.sealedByUser){
            sealedByUser = Array.isArray(this.$route.query.sealedByUser)?(this.$route.query.sealedByUser.map(x => parseInt(x))):[parseInt(this.$route.query.sealedByUser)];
          }
          if(this.$route.query.excludeUser){
            if(sealedByUser.length===0){
              excludeUser = Array.isArray(this.$route.query.excludeUser)?(this.$route.query.excludeUser.map(x => parseInt(x))):[parseInt(this.$route.query.excludeUser)];
            }
          }
          if(this.$route.query.branches){
            branches = Array.isArray(this.$route.query.branches)?(this.$route.query.branches.map(x => parseInt(x))):[parseInt(this.$route.query.branches)];
          }

          this.filters.sealedByUser = sealedByUser
          this.filters.excludeUser = excludeUser
          this.filters.branches = branches

          this.$forceUpdate();
        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async fetchReport(){
        try {
          this.loader = true;
          this.data = null;
          this.totals = null;

          let res = await axios.post(`${this.getEndpoint}/api/reporting/vatsales`, this.filters)
          if(res.data.error) throw res.data.error

          if(!this.$route.query.startDate){
            await this.$router.push({query: {...this.filters}});
          }
          else{
            let changed = false;

            if(Object.keys(this.$route.query).length!==Object.keys(this.filters).length) changed = true;

            if(!changed){
              for(let key of Object.keys(this.$route.query)){
                if(this.filters[key]!=this.$route.query[key] && !Array.isArray(this.filters[key])){
                  changed = true;
                  break;
                }
                if(Array.isArray(this.filters[key])){
                  if(this.filters[key].length!==this.$route.query[key]?.length){
                    changed = true;
                    break;
                  }
                  for(let i=0;i<this.filters[key].length;i++){
                    if(this.filters[key][i]!=this.$route.query[key][i]){
                      changed = true;
                      break;
                    }
                  }
                }
              }
            }
            if(changed){
              await this.$router.replace({query: {...this.filters}});
            }
          }

          if(res.data.data.length===0) throw "No records found within the provided filters."

          this.loadedFilters = {...this.filters}

          this.data = res.data.data;
          this.totals = res.data.totals;

        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async dailySales(){
        this.filters.startDate = moment().format("YYYY-MM-DD");
        this.filters.endDate = moment().format("YYYY-MM-DD");
      },
      async monthlySales(){
        this.filters.startDate = moment().startOf('month').format("YYYY-MM-DD");
        this.filters.endDate = moment().endOf('month').format("YYYY-MM-DD");
      },
      async yearlySales(){
        this.filters.startDate = moment().startOf('year').format("YYYY-MM-DD");
        this.filters.endDate = moment().endOf('year').format("YYYY-MM-DD");
      },
      async printData(){
        let els = document.querySelectorAll(".hideOnPagePrint");
        let defaults = [];
        for(let el of els){
          defaults.push(el.style.display);
          el.style.setProperty("display", "none", "important");
        }

        let els2 = document.querySelectorAll(".adjustForPagePrint");
        let defaults2 = [];
        // let defaults3 = [];
        for(let el of els2){
          defaults2.push(el.style.padding);
          // defaults3.push(el.style.paddingRight);
          el.style.setProperty("padding", "0px 50px 0px 0px");
          // el.style.setProperty("paddingRight", "20px");
        }

        window.print();

        for(let i=0;i<els.length;i++){
          els[i].style.setProperty("display", defaults[i], "");
        }

        for(let i=0;i<els2.length;i++){
          els2[i].style.setProperty("padding", defaults2[i], "");
        }
      },
      async swapUserFields(){
        if(this.filters.sealedByUser.length>0){
          let temp = this.filters.sealedByUser;
          this.filters.sealedByUser = [];
          this.filters.excludeUser = temp;
        }
        else if(this.filters.excludeUser.length>0){
          let temp = this.filters.excludeUser;
          this.filters.excludeUser = [];
          this.filters.sealedByUser = temp;
        }
      },
    }
  }
</script>
