<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class="d-flex flex-row align-center">
          <v-btn @click="$router.go(-1)" text><v-icon>mdi-arrow-left</v-icon></v-btn>
          <h1>Stock History for {{productLabel}}</h1>
          <v-progress-circular color="success" indeterminate v-if="loader"/>
<!--          <v-btn color="info" x-small fab class="ml-2" @click="$router.push(`/products/view/${$route.params.id}`)"><v-icon>mdi-arrow-right</v-icon></v-btn>-->
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3>Filters</h3>
          <span class="d-flex flex-row">
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="filters.startDate"
                    label="Start Date"
                    readonly
                    clearable
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="filters.startDate"
              />
            </v-menu>
            <v-btn small class="mt-1 mx-1" color="warning" @click="filters.endDate=filters.startDate"><v-icon small>mdi-content-copy</v-icon><v-icon small>mdi-arrow-right</v-icon></v-btn>
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="filters.endDate"
                    label="End Date"
                    readonly
                    clearable
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="filters.endDate"
              />
            </v-menu>
            <v-spacer/>
            <v-spacer/>
          </span>
          <span style="margin-top: -20px;" class="d-flex flex-row mb-5">
            <v-btn small class="mr-2" color="error" @click="filters.startDate=null;filters.endDate=null;">Clear Dates</v-btn>
            <v-btn small class="mr-2" color="info" @click="dailyRecords">Today</v-btn>
            <v-btn small class="mr-2" color="info" @click="monthlyRecords">This Month</v-btn>
            <v-btn small class="mr-2" color="info" @click="yearlyRecords">This Year</v-btn>
          </span>

          <span class="d-flex flex-row">
            <v-autocomplete
                class="mr-1 mb-1"
                :disabled="filters.excludedUserIds.length>0"
                v-model="filters.userIds"
                :items="getUserCache"
                :item-text="item=>(item.firstName||'')+' '+(item.lastName||'')"
                item-value="id"
                outlined
                dense
                multiple
                label="Include Users"
                clearable
                hide-details
            ></v-autocomplete>
            <v-btn small :disabled="!filters.excludedUserIds && !filters.userIds" class="mt-1" color="warning" @click="swapUserFields"><v-icon>mdi-swap-horizontal</v-icon></v-btn>
            <v-autocomplete
                class="mx-1 mb-1"
                :disabled="filters.userIds.length>0"
                v-model="filters.excludedUserIds"
                :items="getUserCache"
                multiple
                :item-text="item=>(item.firstName||'')+' '+(item.lastName||'')"
                item-value="id"
                outlined
                dense
                label="Exclude Users"
                clearable
                hide-details
            ></v-autocomplete>
          </span>
          <span>
            <v-btn :loading="loader" color="success" @click="fetchRecords"><v-icon>mdi-magnify</v-icon> Search</v-btn>
          </span>
        </v-col>
      </v-row>
      <v-row v-if="data && data.length===0">
        <v-col>
          <span class="red--text">No records found with the provided filters.</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card :loading="loader">
            <v-card-text>
              <div id="quantityLinesGraph" style="width:100%; height:600px;"></div>
            </v-card-text>
            <hr v-if="requestedLog.data">
            <v-card-text>
              <span v-if="requestedLog.loading" class="d-flex flex-fill justify-space-around">
                <v-progress-circular color="success" indeterminate/>
              </span>
              <span v-else-if="!requestedLog.loading && requestedLog.data">
                <span class="d-flex flex-row justify-space-between">
                  <span>
                    <!--                    <v-chip small class="mr-2" color="success">{{getAction(record.action)}}</v-chip>-->
                    <v-chip small class="mr-2" color="" v-if="isAllowed('logs', 'showRoute')">{{parseURL(requestedLog.data)}}</v-chip>
                    <!--                    <span>{{requestedLog.data.id}}</span>-->
                  </span>
                  <span>
                    <span class="px-2" style="font-size: 14px; font-style: normal;">{{lookupUsername(requestedLog.data.userId)||'ERROR: NO USER'}}</span>
                    <span>{{utils.formatDate(requestedLog.data.createdAt, "withTime")}}</span>
                  </span>
                </span>
                <span class="d-flex flex-column">
                  <v-card outlined class="mt-1 pa-3" v-if="show.changed && requestedLog.data.action==='update'">
                    <h3 v-if="requestedLog.data.data?.locationId">Location: {{lookupLocation(requestedLog.data.data.locationId)}}</h3>
                    <h2>Changed Data</h2>
                    <span class="d-flex flex-column" v-if="requestedLog.data.changedData">
                      <span v-for="(field, f) of requestedLog.data.changedKeys" :key="f">
                        <span class="font-weight-bold">{{visibleProperties.find(y => y.key===field).name}}: </span>
                        <span>{{requestedLog.data.changedData[field].old}} <v-icon>mdi-arrow-right</v-icon> {{requestedLog.data.changedData[field].new}}</span>
                      </span>
                    </span>
                  </v-card>
                  <v-card outlined class="mt-1 pa-3" v-if="show.data || requestedLog.data.action==='create'">
                    <h2>All Data</h2>
                    <span class="d-flex flex-column" v-if="requestedLog.data.data">
                      <span v-for="(field, f) of (Object.keys(requestedLog.data.data).filter(x => visibleProperties.find(y => y.key===x)))" :key="f">
                        <span class="font-weight-bold">{{visibleProperties.find(y => y.key===field).name}}: </span>
                        <span v-if="field==='locationId'">{{lookupLocation(requestedLog.data.data[field])}}</span>
                        <span v-else>{{requestedLog.data.data[field]}}</span>
                      </span>
                    </span>
                  </v-card>
                  <v-card outlined class="mt-1 pa-3" v-if="isAllowed('logs', 'showConnectionDetails') && show.connections">
                    <h2>Connection Details</h2>
                    <span class="d-flex flex-column">
                      <span><span class="font-weight-bold">IPV4 1: </span>{{requestedLog.data.ipv4x1}}</span>
                      <span><span class="font-weight-bold">IPV4 2: </span>{{requestedLog.data.ipv4x2}}</span>
                      <span><span class="font-weight-bold">IPV6: </span>{{requestedLog.data.ipv6}}</span>
                    </span>
                  </v-card>
                  <v-card outlined class="mt-1 pa-3" v-if="isAllowed('logs', 'showMetadata') && show.metadata">
                    <h2>Metadata</h2>
                    <pre>{{JSON.stringify(requestedLog.data.metadata, null, 2)}}</pre>
                  </v-card>
                </span>
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="data">
        <v-col>
          <span class="d-flex flex-row">
            <v-switch class="mr-4" v-model="show.changed" label="Changed Data"/>
            <v-switch class="mr-4" v-if="isAllowed('logs', 'showAllData')" v-model="show.data" label="All Data"/>
            <v-switch class="mr-4" v-if="isAllowed('logs', 'showConnectionDetails')" v-model="show.connections" label="Connection Details"/>
            <v-switch class="mr-4" v-if="isAllowed('logs', 'showMetadata')" v-model="show.metadata" label="Metadata"/>
          </span>
          <span class="d-flex flex-column">
            <span v-if="!filters.limit" class="font-weight-bold">{{`Found ${totalCount} records matching your filters.`}}</span>
            <span v-else class="font-weight-bold">{{`Showing ${filters.limit>totalCount?totalCount:filters.limit} of ${totalCount} records matching your filters.`}}</span>
          </span>
          <span>
            <span class="d-flex flex-row align-center">
              <span style="width: 100px;">
                <v-select v-model="pageLength" hide-details @change="pageCount=1" :items="[5, 10, 20, 30, 50]" label="Per Page" outlined dense/>
              </span>
              <span class="d-flex flex-row ml-2 align-center">
                <v-btn small @click="pageCount>1?pageCount--:null"><v-icon>mdi-chevron-left</v-icon></v-btn>
                <v-text-field v-model="pageCount" style="width: 100px;" dense hide-details outlined label="Current Page"/>
                <v-btn small @click="pageCount<(getTotalPages)?pageCount++:null"><v-icon>mdi-chevron-right</v-icon></v-btn>
                <span class="ml-2">Showing page {{pageCount}} of {{getTotalPages}}</span>
              </span>
            </span>
          </span>
          <span>
            <v-card class="mb-2" outlined v-for="(record, i) of filteredRecords().slice(((pageCount-1)*pageLength), ((pageCount-1)*pageLength)+pageLength)" :key="i">
              <v-card-text class="d-flex flex-column justify-space-between">
                <span class="d-flex flex-row justify-space-between">
                  <span>
                    <b>{{i+((pageCount-1)*pageLength)+1}} </b>
<!--                    <v-chip small class="mr-2" color="success">{{getAction(record.action)}}</v-chip>-->
                    <v-chip small class="mr-2" color="" v-if="isAllowed('logs', 'showRoute')">{{parseURL(record)}}</v-chip>
<!--                    <span>{{record.id}}</span>-->
                  </span>
                  <span>
                    <span class="px-2" style="font-size: 14px; font-style: normal;">{{lookupUsername(record.userId)||'ERROR: NO USER'}}</span>
                    <span>{{utils.formatDate(record.createdAt, "withTime")}}</span>
                  </span>
                </span>
                <span class="d-flex flex-column">
                  <v-card outlined class="mt-1 pa-3" v-if="show.changed && record.action==='update'">
                    <h3 v-if="record.data?.locationId">Location: {{lookupLocation(record.data.locationId)}}</h3>
                    <h2>Changed Data</h2>
                    <span class="d-flex flex-column" v-if="record.changedData">
                      <span v-for="(field, f) of record.changedKeys" :key="f">
                        <span class="font-weight-bold">{{visibleProperties.find(y => y.key===field).name}}: </span>
                        <span>{{record.changedData[field].old}} <v-icon>mdi-arrow-right</v-icon> {{record.changedData[field].new}}</span>
                      </span>
                    </span>
                  </v-card>
                  <v-card outlined class="mt-1 pa-3" v-if="show.data || record.action==='create'">
                    <h2>All Data</h2>
                    <span class="d-flex flex-column" v-if="record.data">
                      <span v-for="(field, f) of (Object.keys(record.data).filter(x => visibleProperties.find(y => y.key===x)))" :key="f">
                        <span class="font-weight-bold">{{visibleProperties.find(y => y.key===field).name}}: </span>
                        <span v-if="field==='locationId'">{{lookupLocation(record.data[field])}}</span>
                        <span v-else>{{record.data[field]}}</span>
                      </span>
                    </span>
                  </v-card>
                  <v-card outlined class="mt-1 pa-3" v-if="isAllowed('logs', 'showConnectionDetails') && show.connections">
                    <h2>Connection Details</h2>
                    <span class="d-flex flex-column">
                      <span><span class="font-weight-bold">IPV4 1: </span>{{record.ipv4x1}}</span>
                      <span><span class="font-weight-bold">IPV4 2: </span>{{record.ipv4x2}}</span>
                      <span><span class="font-weight-bold">IPV6: </span>{{record.ipv6}}</span>
                    </span>
                  </v-card>
                  <v-card outlined class="mt-1 pa-3" v-if="isAllowed('logs', 'showMetadata') && show.metadata">
                    <h2>Metadata</h2>
                    <pre>{{JSON.stringify(record.metadata, null, 2)}}</pre>
                  </v-card>
                </span>
              </v-card-text>
            </v-card>
          </span>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
  table{
    border-collapse: collapse;
  }
  tr{
    border-bottom: 1px solid #ccc;
  }
  .CodeMirrorHeightAuto *{
    height: auto;
  }
</style>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  import utils from "../../plugins/helpers"
  import moment from "moment/moment";
  import * as echarts from 'echarts';
  export default {
    data () {
      return {
        echarts: echarts,
        utils: utils,
        deleteDialog: false,
        deleteConfirmed: true,
        loader: false,
        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        filters: {
          startDate: null,
          endDate: null,
          userIds: [],
          excludedUserIds: []
        },

        show: {
          metadata: false,
          connections: false,
          data: false,
          changed: true
        },

        actions: [{text: "Create", value: "create"}, {text: "Update", value: "update"}, {text: "Delete", value: "destroy"}, {text: "Restore", value: "restore"}],

        data: null,
        chart: null,
        graph: null,

        pageCount: 1,
        pageLength: 20,
        startLength: 4,
        endLength: 3,

        requestedLog: {
          loading: false,
          data: null
        },

        totalCount: 0,

        productLabel: "",

        visibleProperties: [
          {name: "In Stock", key: "quantity"},
          {name: "Available", key: "available"},
          {name: "Manage Stock", key: "manageStock"},
          {name: "Minimum Quantity", key: "minQuantity"},
          {name: "Notify on Minimum Quantity", key: "notifyOnMinQuantity"},
          {name: "Location", key: "locationId"},
          {name: "Commend", key: "comment"}
        ],

        urlDictionary: [
          {url: '/api/products/updateGeneral', label: "Product Update", exclude: [], include: []},
          {url: '/api/orders/changeStatus/voided', label: "Order Voided", exclude: [{isBranch: true, key: "quantity"}], include: []},
          {url: '/api/orders/changeStatus/sealed', label: "Order Sealed", exclude: [{isBranch: true, key: "quantity"}], include: []},
          {url: '/api/dispatch/confirm', label: "Dispatch Confirmed", exclude: [{isBranch: true, key: "quantity"}], include: []},
          {url: '/api/receivingreports/seal', label: "Receiving Report Sealed", exclude: [{isBranch: true, key: "quantity"}], include: []},
          {url: '/api/receivingreports/void', label: "Receiving Report Voided", exclude: [{isBranch: true, key: "quantity"}], include: []},
          {url: '/api/inventorytransfers/sendOut', label: "Inventory Transfer Sent Out", exclude: [{isBranch: true, key: "quantity"}], include: []},
          {url: '/api/inventorytransfers/receiveIn', label: "Inventory Transfer Received In", exclude: [{isBranch: true, key: "quantity"}], include: []},
          {url: '/api/returns/sign', label: "Return Sealed", exclude: [{isBranch: true, key: "quantity"}], include: []},
          {url: '/api/products/updateLocations', label: "Product Warehouse Update", exclude: [{isBranch: true, key: "quantity"}], include: []},
          {url: '/api/inventorytransfers/void', label: "Inventory Transfer Voided", exclude: [{isBranch: true, key: "quantity"}], include: []},
          {url: '/api/products', label: "Product Created", exclude: [], include: [], action: "create"},
          {url: '/api/custom', label: "Admin Action", exclude: [], include: []},
          {url: '/api/sync', label: "Admin Action", exclude: [], include: []},
        ]
      //   {isBranch: true, key: "quantity"}
      }
    },
    created(){
      window.addEventListener("resize", this.reAdjustGraph);
    },
    destroyed() {
      window.removeEventListener("resize", this.reAdjustGraph);
    },
    async mounted(){
      await this.getAllData();
    },
    computed: {
      ...mapGetters(['getEndpoint', "isAllowed", 'lookupUsername', 'getUserCache', 'getBranches', 'getGlobalValue', 'paymentTypes', 'lookupLocation']),
      getTotalPages(){
        return parseInt((this.data.length/this.pageLength)+(this.data.length%this.pageLength===0?0:1))
      }
    },
    methods: {
      filteredRecords(){
        let filtered = []

        for(let d of this.data){
          let keys = null;
          if(d.changedData){
            keys = this.filterChangedDataKeys(d);
            if(keys.length===0) continue;
          }

          filtered.push({...d, changedKeys: keys})
        }

        return filtered
      },
      filterChangedDataKeys(record){
        let keys =  Object.keys(record.changedData).filter(x => this.visibleProperties.find(y => y.key===x))
        let url = this.getDictUrl(record.route)

        if(!url) return keys

        let isBranch = this.getBranches.findIndex(x => record.data?.locationId===x.id)>=0;

        for(let ex of url.exclude){
          if(keys.includes(ex.key) && (isBranch==ex.isBranch)) keys.splice(keys.indexOf(ex.key), 1)
        }

        for(let inc of url.include){
          if(!keys.includes(inc.key) && (isBranch==inc.isBranch)) keys.splice(keys.indexOf(inc.key), 1)
        }

        return keys
      },
      getDictUrl(url, action=null){
        let d = this.urlDictionary.find(x => url===x.url || url.startsWith(x.url) || url.includes(x.url))
        if(d.action && d.action!==action) return null
        return d
      },
      parseURL(record){
        let url = record.route
        let found = this.getDictUrl(url, record.action)
        if(!found) return `Admin Action or Unresolved${this.isAllowed('logs', 'showRoute')?' ('+url+')':''}`;

        let endOfURL = url.split("/")[url.split("/").length-1];

        return `${found.label}${!isNaN(endOfURL)?' (ID: '+endOfURL+')':''}${this.isAllowed('logs', 'showRoute')?' ('+url+')':''}`
      },
      reAdjustGraph(){
        if(!this.graph) return;

        this.graph.resize();
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      getAction(action){
        let a = this.actions.find(x => x.value===action);
        return a?.text || ""
      },
      async getAllData(){
        try {
          this.loader = true;

          let res = await axios.get(`${this.getEndpoint}/api/products/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error
          if(res.data.data){
            this.productLabel = [`ID: ${res.data.data.id}`, res.data.data.name, res.data.data.sku].filter(x => x).join(" | ")
          }

          let userIds = [];
          let excludedUserIds = [];


          if(this.$route.query.startDate){
            let firstDay = new Date(this.$route.query.startDate);
            this.filters.startDate = this.utils.parseDate(firstDay);
          }
          if(this.$route.query.endDate){
            let lastDay = new Date(this.$route.query.endDate);
            this.filters.endDate = this.utils.parseDate(lastDay);
          }
          if(this.$route.query.userIds){
            userIds = Array.isArray(this.$route.query.userIds)?(this.$route.query.userIds.map(x => parseInt(x))):[parseInt(this.$route.query.userIds)];
          }
          if(this.$route.query.excludedUserIds){
            if(userIds.length===0){
              excludedUserIds = Array.isArray(this.$route.query.excludedUserIds)?(this.$route.query.excludedUserIds.map(x => parseInt(x))):[parseInt(this.$route.query.excludedUserIds)];
            }
          }

          this.filters.userIds = userIds
          this.filters.excludedUserIds = excludedUserIds

          this.$forceUpdate();

          await this.fetchRecords()
        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async fetchRecords(){
        try {
          this.loader = true;

          this.data = null;
          this.totalCount = 0;

          let res = await axios.post(`${this.getEndpoint}/api/logs/productHistory/${this.$route.params.id}`, this.filters)
          if(res.data.error) throw res.data.error

          let changed = false;
          let tempObj = {...this.filters}
          delete tempObj.product

          if(Object.keys(this.$route.query).length!==Object.keys(tempObj).length) changed = true;
          if(!changed){
            for(let key of Object.keys(this.$route.query)){
              if(tempObj[key]!=this.$route.query[key] && !Array.isArray(tempObj[key])){
                if((this.$route.query[key]==='true' && tempObj[key]) || (this.$route.query[key]==='false' && !tempObj[key])) continue;
                changed = true;
                break;
              }
              if(Array.isArray(tempObj[key])){
                if(Object.keys(this.$route.query[key]).length!==Object.keys(tempObj[key]).length){
                  changed = true;
                  break;
                }
                for(let i=0;i<tempObj[key].length;i++){
                  if(tempObj[key][i]!=this.$route.query[key][i]){
                    changed = true;
                    break;
                  }
                }
              }
            }
          }

          if(changed) await this.$router.replace({query: {...tempObj}});
          if(res.data.data.length===0) throw "No records found within the provided filters."

          this.data = res.data.data;
          this.chart = res.data.chart;
          this.totalCount = res.data.totalCount||0;

          console.log(res.data)

          this.generateGraph()

        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      generateGraph(){
        if(!this.chart) return;

        let chart = this.chart;
        if(!this.graph) this.graph = echarts.init(document.getElementById('quantityLinesGraph'));

        this.graph.showLoading()

        if(chart.style?.percentLabel){
          chart.option.series[0].label.formatter = function(params) {
            let percent = (params.percent).toFixed(chart.style.percentFloats||0) + '%';
            return params.name + '\n' + percent;
          }
        }

        if(chart.style?.hideZero){
          chart.option.label.formatter = function(params) {
            if (params.value !== 0) {
              return params.value;
            } else {
              return '';
            }
          }
        }

        if(chart.style?.parseLegend){
          if(chart.style.parseLegend.type==="function"){
            let fn = new Function(chart.style.parseLegend.data)
            chart.option.legend.formatter = fn;
          }
        }

        chart.option.xAxis.axisLabel = {
          formatter: function (value){
            return utils.formatDate(value, 'withTime')
          }
        }

        chart.option.tooltip = {
          ...chart.option.tooltip,
          formatter : (params) => {
            let xTime = new Date(params[0].axisValue)
            let tooltip = ``;
            chart.option.series.forEach((series, index) => {
              let value = series.data.reduce((prev, curr) => Math.abs(new Date(curr[0]).valueOf() - xTime.valueOf()) < Math.abs(new Date(prev[0]).valueOf() - xTime.valueOf()) ? curr : prev)
              tooltip += `<p><span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color: ${this.graph.getVisual({ seriesIndex: index }, 'color')}"></span>`
              tooltip += `${series.name}</br><b>${value[1]}</b> | ${utils.formatDate(value[0], 'withTime')}</p>`;
            });
            return tooltip;
          }
        }

        this.graph.setOption(chart.option)

        this.graph.on('click', async(params) => {
          if(params.value?.length===3){
            this.requestedLog.loading = true

            let res = await axios.get(`${this.getEndpoint}/api/logs/${params.value[2]}`)
            if(res.data.error) throw res.data.error
            this.requestedLog = {
              data: res.data.data,
              loading: false
            }
          }
        });

        this.$forceUpdate()

        this.graph.hideLoading()
      },

      async swapUserFields(){
        if(this.filters.userIds.length>0){
          let temp = this.filters.userIds;
          this.filters.userIds = [];
          this.filters.excludedUserIds = temp;
        }
        else if(this.filters.excludedUserIds.length>0){
          let temp = this.filters.excludedUserIds;
          this.filters.excludedUserIds = [];
          this.filters.userIds = temp;
        }
      },

      async dailyRecords(){
        this.filters.startDate = moment().format("YYYY-MM-DD");
        this.filters.endDate = moment().format("YYYY-MM-DD");
      },
      async monthlyRecords(){
        this.filters.startDate = moment().startOf('month').format("YYYY-MM-DD");
        this.filters.endDate = moment().endOf('month').format("YYYY-MM-DD");
      },
      async yearlyRecords(){
        this.filters.startDate = moment().startOf('year').format("YYYY-MM-DD");
        this.filters.endDate = moment().endOf('year').format("YYYY-MM-DD");
      },
    }
  }
</script>
